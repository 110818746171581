<script setup lang="ts">
import MSelect from "@mumble/components/select/Select.vue";
import MCheckbox from "@mumble/components/checkbox/Checkbox.vue";
import MOverlay from "@mumble/components/overlay/Overlay.vue";
import KUpload from "@mumble/components/upload/Upload.vue";
import {useAlert, useValidations, useBreakpoints} from "@mumble/hooks";
import {formDefaults, formValidations, prefixPhonesOptions} from "assets/js/constants/index.constants";
import type {UploadRemoveFileEmit} from "@mumble/components/types";
import {appendFormValues} from "assets/js/helpers";
import type {Form, FormFileTypes} from "assets/js/types";
import {ArrowRightIcon} from '@heroicons/vue/24/outline'

const isLoading = ref(false)
const {form, errors: inputErrors, errorsAll, hasErrors, clear} = useValidations(formDefaults, formValidations, {
    locale: 'it',
})

const { isMobile } = useBreakpoints()
const showErrors = ref(false)
const errors = computed(() => showErrors.value ? errorsAll.value : inputErrors.value)
const alertValidation = useAlert()

const alert = computed(() => alertValidation)
const portraitError = ref(false)
const projectsError = ref(false)
const workInProgressError = ref(false)
const poseProjectsError = ref(false)
const documentsError = ref(false)
const termsError = ref(false)

const active = ref(1)

const setAlertError = (content: string = 'Compila tutti i campi obbligatori per continuare') => {
    alertValidation.setContent(content)
    alertValidation.show()
    // window.scrollTo({top: 0, behavior: 'smooth'})
}

const onRemoveFile = (file: UploadRemoveFileEmit, type: FormFileTypes) => {
    form.value[type] = form.value[type].filter((f) => f.id !== file.file.id)
}

const isModalSubmitOpen = ref(false)
const onSubmit = async () => {

    let internalHasErrors = false
    showErrors.value = true

    if (hasErrors.value) {
        setAlertError()
        internalHasErrors = true
    }

    if (form.value.portrait.length < 1) {
        portraitError.value = true
        setAlertError()
        internalHasErrors = true
    }

    if (form.value.projects.length < 5) {
        projectsError.value = true
        setAlertError()
        internalHasErrors = true
    }

    if (form.value.workInProgress.length < 5) {
        workInProgressError.value = true
        setAlertError()
        internalHasErrors = true
    }

    if (form.value.poseProjects.length < 3) {
        poseProjectsError.value = true
        setAlertError()
        internalHasErrors = true
    }

    if (internalHasErrors) return

    const body = new FormData()

    // @ts-ignore
    const fields = Object.keys(form.value).filter((key) => !Array.isArray(form.value[key])) as (keyof Form)[];
    appendFormValues(fields, body, form)

    const categories: FormFileTypes[] = ['portrait', 'projects', 'workInProgress', 'poseProjects', 'documents'];

    const files: { file: File, category: FormFileTypes }[] = []
    categories.forEach(category => {
        form.value[category].forEach((file) => {
            files.push({file: file.raw!, category})
        })
    })

    isLoading.value = true
    try {
        const responses = await Promise.all(files.map(file => {
            return fetch(`/api/generateUploadUrl?name=${file.file.name}&type=${file.file.type}&userName=${form.value.name.toLowerCase()}&userSurname=${form.value.surname.toLowerCase()}`);
        }));
        const data = (await Promise.all(responses.map(response => response.json())));
        const uploadUrls = data.map((d: any) => d.uploadURL);
        const keys = data.map((d: any) => d.key);

        await Promise.all(files.map((file, index) => {
            return fetch(uploadUrls[index], {
                method: 'PUT',
                body: file.file,
                headers: {
                    'Content-Type': file.file.type,
                },
            });
        }));

        const config = useRuntimeConfig()
        let categoriesUrls: any = {
            portrait: '',
            projects: '',
            workInProgress: '',
            poseProjects: '',
            documents: '',
        }
        files.forEach((file, index) => {
            categoriesUrls[file.category] += `${config.public.s3Cdn}/${keys[index]}
`
        })

        Object.keys(categoriesUrls).forEach((category) => {
            body.append(category, categoriesUrls[category])
        })

        const {error} = await useFetch('/api/saveForm', {
            method: 'POST',
            body,
        })


        if (error.value) {
            setAlertError(error.value.message)
        } else {
            // alertValidation.setContent('Form inviato con successo')
            // alertValidation.setType('success')
            // alertValidation.show()
            window.scrollTo({top: 0, behavior: 'smooth'})
            isModalSubmitOpen.value = true
            active.value = 1

            clear()
            form.value.portrait = []
            form.value.projects = []
            form.value.workInProgress = []
            form.value.poseProjects = []
            form.value.documents = []
            showErrors.value = false
        }
    } catch (e) {
        console.error(e)
        setAlertError('Errore durante il caricamento dei file')
    }
    isLoading.value = false
}

const isModalOpen = ref(false)

const onNext = () => {
    if (active.value === 1) {
        if (!form.value.name || !form.value.surname || !form.value.qualification || !form.value.email || !form.value.cap || !form.value.prefix || !form.value.phone || !form.value.studio || !form.value.sector || !form.value.professionalPath) {
            setAlertError()
            return
        }
    }

    if (active.value === 2) {
        if (!form.value.design1 || !form.value.design2 || !form.value.design3 || !form.value.sustainability) {
            setAlertError()
            return
        }
    }

    if (active.value === 3) {
        if (!form.value.product1 || !form.value.product2) {
            setAlertError()
            return
        }
    }

    if (active.value === 4) {
        let internalHasErrors = false

        if (form.value.portrait.length < 1) {
            portraitError.value = true
            setAlertError()
            internalHasErrors = true
        }

        if (form.value.projects.length < 5) {
            projectsError.value = true
            setAlertError()
            internalHasErrors = true
        }

        if (form.value.workInProgress.length < 5) {
            workInProgressError.value = true
            setAlertError()
            internalHasErrors = true
        }

        if (form.value.poseProjects.length < 3) {
            poseProjectsError.value = true
            setAlertError()
            internalHasErrors = true
        }

        if (!form.value.terms) {
            termsError.value = true
            setAlertError()
            internalHasErrors = true
        }

        if (internalHasErrors) return
    }

    alertValidation.hide()

    active.value++

    setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, 200)
}

const goToStep = (step: number) => {
    active.value = step
    setTimeout(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, 200)
}

useHead({
    title: 'Kerakoll | H40 Supports my Ideas.',
    meta: [
        {
            name: 'description',
            content: 'Siete esperti del settore edile con storie interessant da raccontare? Scriveteci le vostre esperienze, i progetti di cui siete più orgogliosi, le idee innovative del vostro Studio e il support che Kerakoll vi ha offerto per realizzarle. Condividete il vostro percorso con noi e ispirate altri professionisti.'
        }
    ],
    script: [
        {
            innerHTML: `
            _linkedin_partner_id = "7791537";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        `,
            tagPosition: 'bodyClose'
        },
        {
            innerHTML: `
              (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
            `,
            tagPosition: 'bodyClose'
        }
    ],
    noscript: [
        {
            innerHTML: `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=7791537&fmt=gif" />`,
            tagPosition: 'bodyClose'
        }
    ]
})

useSeoMeta({
    title: 'Kerakoll | H40 Supports my Ideas.',
    ogTitle: 'Kerakoll | H40 Supports my Ideas.',
    description: 'Siete esperti del settore edile con storie interessant da raccontare? Scriveteci le vostre esperienze, i progetti di cui siete più orgogliosi, le idee innovative del vostro Studio e il support che Kerakoll vi ha offerto per realizzarle. Condividete il vostro percorso con noi e ispirate altri professionisti.',
    ogDescription: 'Siete esperti del settore edile con storie interessant da raccontare? Scriveteci le vostre esperienze, i progetti di cui siete più orgogliosi, le idee innovative del vostro Studio e il support che Kerakoll vi ha offerto per realizzarle. Condividete il vostro percorso con noi e ispirate altri professionisti.',
    ogImage: '/img/img-seo.jpg',
    twitterCard: 'summary_large_image',
})

const hasOpenTerms = ref(false)

</script>

<template>
    <div class="container">
        <form class="mb-16" @submit.prevent="onSubmit">
            <KHero/>

            <Transition appear name="fade" mode="out-in">
                <div v-if="active === 1">
                    <div class="relative mt-10 mb-16 px-4 lg:px-20 py-20 bg-cover bg-center text-white text-center"
                         style="background-image: url('/img/hero.jpg')">
                        <MOverlay/>
                        <div class="relative z-10">
                            <NuxtImg src="/img/logo-smi.svg" width="300" height="115" class="mx-auto mb-20"
                                     alt="placeholder"/>
                            <h2 class="leading-snug lg:leading-[75px]">
                                Architetti, ingegneri, progettisti e designer, <br /> è il vostro momento
                            </h2>
                            <p class="font-semibold mt-10 max-w-[600px] mx-auto mb-20">
                                Siete esperti del settore edile con storie interessanti da raccontare? Scriveteci le
                                vostre
                                esperienze, i progetti di cui siete più orgogliosi, le idee innovative del vostro Studio
                                e il
                                supporto che Kerakoll vi ha offerto per realizzarle. Condividete il vostro percorso con
                                noi e
                                ispirate altri professionisti.
                            </p>
                        </div>
                    </div>
                    <h2 class="mb-5">I tuoi dati</h2>
                    <client-only>
                        <MGrid class="mb-16" gap="4" padding="2">
                            <MGridColumn lg="4">
                                <MInput v-model:value="form.name" :error="errors.name" label="Nome" placeholder="Nome*"
                                        label-hidden/>
                            </MGridColumn>
                            <MGridColumn lg="4">
                                <MInput v-model:value="form.surname" :error="errors.surname" label="Cognome"
                                        placeholder="Cognome*"
                                        label-hidden/>
                            </MGridColumn>
                            <MGridColumn lg="4">
                                <MSelect v-model:value="form.qualification" :error="errors.qualification" label="Qualifica"
                                         placeholder="Qualifica*" label-hidden :options="[
                                 { label: 'Architetto', value: 'Architetto' },
                                 { label: 'Rivenditore', value: 'Rivenditore' },
                                 { label: 'Impresa edile', value: 'Impresa edile' },
                                 { label: 'Progettista', value: 'Progettista' },
                                 { label: 'Interior designer', value: 'Interior designer' },
                                 { label: 'Applicatore', value: 'Applicatore' },
                                 { label: 'Privato', value: 'Privato' },
                                 { label: 'Altro', value: 'Altro' },
                             ]"/>
                            </MGridColumn>
                            <MGridColumn lg="4">
                                <MInput v-model:value="form.email" name="email" type="email" :error="errors.email"
                                        label="Email"
                                        placeholder="Email*" label-hidden/>
                            </MGridColumn>
                            <template v-if="!isMobile">
                                <MGridColumn lg="4">
                                    <div class="flex gap-4">
                                        <MInput
                                            v-model:value="form.cap"
                                            :error="errors.cap"
                                            label="CAP"
                                            placeholder="CAP*"
                                            label-hidden
                                        />
                                        <MSelect search v-model:value="form.prefix" :error="errors.prefix"
                                                 label="Phone prefix" placeholder="+39" label-hidden
                                                 :options="prefixPhonesOptions"/>
                                    </div>
                                </MGridColumn>
                                <MGridColumn lg="4">
                                    <div class="flex gap-4">
                                        <MInput class="flex-1" v-model:value="form.phone" type="tel" :error="errors.phone"
                                                label="Email" placeholder="Telefono*" label-hidden/>
                                    </div>
                                </MGridColumn>
                            </template>
                            <template v-else>
                                <MGridColumn>
                                    <MInput
                                        v-model:value="form.cap"
                                        :error="errors.cap"
                                        label="CAP"
                                        placeholder="CAP*"
                                        label-hidden
                                    />
                                </MGridColumn>
                                <MGridColumn>
                                    <div class="flex gap-4">
                                        <MSelect class="w-28" search v-model:value="form.prefix" :error="errors.prefix"
                                                 label="Prefisso" placeholder="+39" label-hidden
                                                 :options="prefixPhonesOptions"/>
                                        <MInput class="flex-1" v-model:value="form.phone" type="tel" :error="errors.phone"
                                                label="Email" placeholder="Phone*" label-hidden/>
                                    </div>
                                </MGridColumn>
                            </template>
                            <MGridColumn lg="6">
                                <div class="flex gap-4">
                                    <MInput class="flex-1" v-model:value="form.studio" :error="errors.studio"
                                            label="Nome dello studio" placeholder="Nome dello Studio*" label-hidden/>
                                </div>
                            </MGridColumn>
                            <MGridColumn lg="6">
                                <MSelect v-model:value="form.sector" :error="errors.sector" label="Settore"
                                         placeholder="Settore*" label-hidden :options="[
                                 { label: 'Ingegneria', value: 'Ingengeria' },
                                    { label: 'Architettura', value: 'Architettura' },
                                    { label: 'Interior design', value: 'Interior design' },
                                    { label: 'Landscape & Garden Design', value: 'Landscape & Garden Design' },
                                    { label: 'Altro', value: 'Altro' },
                             ]"/>
                            </MGridColumn>
                        </MGrid>
                    </client-only>

                    <h2>Il percorso professionale</h2>
                    <KTextMuted class="mb-5 mt-3 lg:mt-0">Rispondi con un massimo di 1000 battute a domanda</KTextMuted>
                    <div class="mb-2.5">
                        <p class="font-larish text-lg">Di quali progetti si occupa principalmente il tuo Studio?</p>
                        <MInput :max-length="1000" show-character-count v-model:value="form.professionalPath"
                                :error="errors.professionalPath" label="Qual è il tuo percorso professionale"
                                label-hidden
                                :multiline="5" placeholder="Inserisci risposta*"/>
                    </div>
                </div>
                <div v-else-if="active === 2">
                    <h2>Il ruolo della progettazione</h2>
                    <KTextMuted class="mb-5 mt-3 lg:mt-0">Rispondi con un massimo di 1000 battute a domanda</KTextMuted>
                    <div class="mb-16">
                        <div class="mb-6">
                            <p class="font-larish text-lg">Quale valore date alla progettazione degli ambienti?</p>
                            <MInput :max-length="1000" show-character-count v-model:value="form.design1"
                                    :error="errors.design1"
                                    label="Cos’è per te la progettazione architettonica ed edilizia?" label-hidden
                                    :multiline="5" placeholder="Inserisci risposta*"/>
                        </div>
                        <div class="mb-6">
                            <p class="font-larish text-lg">Quale elemento guida tutti i vostri progetti?</p>
                            <MInput :max-length="1000" show-character-count v-model:value="form.design2"
                                    :error="errors.design2"
                                    label="Qual è il principio comune che guida tutti i tuoi progetti?" label-hidden
                                    :multiline="5" placeholder="Inserisci risposta*"/>
                        </div>
                        <div class="mb-6">
                            <p class="font-larish text-lg">Quanto è importante lavorare con i giusti partner (imprese
                                edili, artigiani, aziende, fornitori...) per realizzare dei buoni progetti e come li
                                scegli?</p>
                            <MInput :max-length="1000" show-character-count v-model:value="form.design3"
                                    :error="errors.design3"
                                    label="Come riesci a rendere gli spazi realizzati vivibili e accoglienti?"
                                    label-hidden
                                    :multiline="5" placeholder="Inserisci risposta*"/>
                        </div>
                    </div>

                    <h2>La sostenibilità</h2>
                    <KTextMuted class="mb-5 mt-3 lg:mt-0">Rispondi con un massimo di 1000 battute a domanda</KTextMuted>
                    <div class="mb-2.5">
                        <p class="font-larish text-lg">La sostenibilità ambientale, sociale e di governance è un tema
                            sempre più rilevante per le persone e le comunità.
                            <br/> Questi principi come trovano riscontro nella vostra progettazione?</p>
                        <MInput :max-length="1000" show-character-count v-model:value="form.sustainability"
                                :error="errors.sustainability"
                                label="Per Kerakoll la sostenibilità (ambientale, sociale e di gestione) è un elemento centrale. Quanto è importante questa tematica nei tuoi progetti?"
                                label-hidden :multiline="5" placeholder="Inserisci risposta*"/>
                    </div>
                </div>
                <div v-else-if="active === 3">
                    <div class="mb-16">
                        <h2>Tra progetti di posa e caratteristiche di prodotto</h2>
                        <KTextMuted class="mb-5 mt-3 lg:mt-0">
                            Rispondi con un massimo di 1000 battute a domanda
                        </KTextMuted>
                        <div class="mb-16">
                            <div class="mb-6">
                                <p class="font-larish text-lg">Raccontateci i dettagli di un cantiere dove avete
                                    utilizzato i prodotti per la posa Kerakoll.</p>
                                <MInput :max-length="1000" show-character-count v-model:value="form.product1"
                                        :error="errors.product1"
                                        label="Ci descrivi il progetto più significativo in cui hai usato il Sistema posa Kerakoll e in particolare la Linea di Gel-Adesivi H40?"
                                        label-hidden :multiline="5" placeholder="Inserisci risposta*"/>
                            </div>
                            <div class="mb-6">
                                <p class="font-larish text-lg">La posa nei cantieri è un momento determinante e da
                                    eseguire con la massima cura, scegliendo i materiali adeguati per ogni supporto. Nei
                                    vostri progetti, qual è stato il valore aggiunto nell’utilizzare i prodotti
                                    Kerakoll? Quali prestazioni vi hanno garantito?</p>
                                <MInput :max-length="1000" show-character-count v-model:value="form.product2"
                                        :error="errors.product2"
                                        label="La posa nei cantieri è un momento determinante e da eseguire con la massima cura, scegliendo i materiali adeguati per ogni supporto. Nei vostri progetti, qual è stato il valore aggiunto nell’utilizzare i prodotti Kerakoll?"
                                        label-hidden
                                        :multiline="5" placeholder="Inserisci risposta*"/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2>Altro</h2>
                        <KTextMuted class="mb-5 mt-3 lg:mt-0">
                            Rispondi con un massimo di 1000 battute a domanda
                        </KTextMuted>
                        <div class="mb-16">
                            <div class="mb-2.5">
                                <p class="font-larish text-lg">Aggiungete qualsiasi altra informazione utile per
                                    valorizzare il vostro progetto. (ad esempio premi, certificazioni, etc..)</p>
                                <MInput :max-length="1000" show-character-count v-model:value="form.other"
                                        :error="errors.other"
                                        label="Aggiungete qualsiasi altra informazione utile per valorizzare il vostro progetto. (ad esempio premi, certificazioni, etc..)"
                                        label-hidden :multiline="5" placeholder="Inserisci risposta"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="active === 4">
                    <h2>Immagini</h2>
                    <div class="mb-10">
                        <div :class="['mb-6', {'upload--error': portraitError}]">
                            <p>⇀ <span class="font-larish text-lg">Ritratto</span> <span class="text-xs text-muted">- da 1 a 3 immagini.</span>
                            </p>
                            <KUpload accept="image/*" :max-size="5" size-error-label="Dimensione massima 5MB"
                                     v-model:files="form.portrait" async label="Ritratto" label-hidden multiple :max="3"
                                     @remove-file="onRemoveFile($event, 'portrait')">
                                <div>
                                    <p>
                                        Allega i file trascinandoli qui <br> o <br> caricali dal computer
                                    </p>
                                </div>
                            </KUpload>
                        </div>
                        <div :class="['mb-6', {'upload--error': projectsError}]">
                            <p>⇀ <span
                                class="font-larish text-lg">Immagini di progetti finiti citati nelle risposte</span>
                                <span class="text-xs text-muted">- da 5 a 15 immagini.</span></p>
                            <KUpload accept="image/*" :max-size="5" size-error-label="Dimensione massima 5MB"
                                     v-model:files="form.projects" async label="Ritratto" label-hidden multiple
                                     :max="15"
                                     @remove-file="onRemoveFile($event, 'projects')">
                                <div>
                                    <p>
                                        Allega i file trascinandoli qui <br> o <br> caricali dal computer
                                    </p>
                                </div>
                            </KUpload>
                        </div>
                        <div :class="['mb-6', {'upload--error': workInProgressError}]">
                            <p>⇀ <span
                                class="font-larish text-lg">Immagini di “lavori in corso” dei progetti citati <br/>nelle risposte (render, schizzi, immagini del cantiere ... )</span>
                                <span class="text-xs text-muted">- da 5 a 15 immagini.</span></p>
                            <KUpload accept="image/*" :max-size="5" size-error-label="Dimensione massima 5MB"
                                     v-model:files="form.workInProgress" async
                                     label="Immagini di “lavori in corso” dei progetti citati nelle risposte (render, schizzi, immagini del cantiere ... )"
                                     label-hidden multiple :max="15"
                                     @remove-file="onRemoveFile($event, 'workInProgress')">
                                <div>
                                    <p>
                                        Allega i file trascinandoli qui <br> o <br> caricali dal computer
                                    </p>
                                </div>
                            </KUpload>
                        </div>

                        <div :class="['mb-6', {'upload--error': poseProjectsError}]">
                            <p>⇀ <span class="font-larish text-lg">Immagini di progetti di posa, anche non citati nelle risposte, in cui sono stati utilizzati i prodotti del Sistema posa Kerakoll e in particolare i Gel- Adesivi H40</span>
                                <span class="text-xs text-muted">- da 3 a 10 immagini.</span></p>
                            <KUpload accept="image/*" :max-size="5" size-error-label="Dimensione massima 5MB"
                                     v-model:files="form.poseProjects" async
                                     label="Immagini di progetti di posa, anche non citati nelle risposte, in cui sono stati utilizzati i prodotti del Sistema posa Kerakoll e in particolare i Gel- Adesivi H40"
                                     label-hidden multiple :max="10"
                                     @remove-file="onRemoveFile($event, 'poseProjects')">
                                <div>
                                    <p>
                                        Allega i file trascinandoli qui <br> o <br> caricali dal computer
                                    </p>
                                </div>
                            </KUpload>
                        </div>

                        <div :class="['mb-6', {'upload--error': documentsError}]">
                            <p>⇀ <span class="font-larish text-lg">
                                        Documentazione video dei progetti citati, sia conclusi sia in fase di realizzazione
                                    </span> <span class="text-xs text-muted">(facoltativo).</span></p>
                            <KUpload accept="video/*" :max-size="5" size-error-label="Dimensione massima 5MB"
                                     v-model:files="form.documents" async
                                     label="Documentazione video dei progetti citati, sia conclusi sia in fase di realizzazione"
                                     label-hidden multiple @remove-file="onRemoveFile($event, 'documents')">
                                <div>
                                    <p>
                                        Allega i file trascinandoli qui <br> o <br> caricali dal computer
                                    </p>
                                </div>
                            </KUpload>
                        </div>


                    </div>

                    <div>
                        <MCheckbox
                            :disabled="!hasOpenTerms"
                            :class="['mb-5', { 'checkbox--error': errors.terms || termsError }]"
                            v-model:value="form.terms"
                        >
                            Ho letto e accetto i <a class="underline" href="javascript:void(0)" role="button"
                                                    @click="() => {
                                                        hasOpenTerms = true
                                                        isModalOpen = true
                                                    }">termini e le condizioni</a>
                        </MCheckbox>
                    </div>

                    <div>
                        <MCheckbox :class="['mb-5', { 'checkbox--error': errors.marketing }]"
                                   v-model:value="form.marketing">
                            Desidero restare aggiornato con tutte le novità del mondo Kerakoll
                        </MCheckbox>
                    </div>
                    <p class="text-muted mb-10"><small>Kerakoll si riserva di selezionare i progetti da pubblicare in base alla quantità di materiale ricevuto. In caso di selezione sarete contattati da una persona Kerakoll di riferimento.</small></p>
                </div>
                <div v-else>

                    <div class="border border-primary p-3 mb-3">
                        <h4 class="mb-5">Il percorso professionale</h4>
                        <p class="font-larish text-lg">Di quali progetti si occupa principalmente il tuo studio?</p>
                        <p>
                            {{ form.professionalPath }}
                        </p>
                    </div>

                    <MButton class="mb-10" primary @click="goToStep(1)">
                        Modifica risposte
                    </MButton>

                    <div class="border border-primary p-3 mb-3">
                        <h4 class="mb-5">Il ruolo della progettazione</h4>
                        <p class="font-larish text-lg">Quale valore date alla progettazione degli ambienti?</p>
                        <p>
                            {{ form.design1 }}
                        </p>
                        <p class="font-larish text-lg">Quale elemento guida tutti i vostri progetti?</p>
                        <p>
                            {{ form.design2 }}
                        </p>
                        <p class="font-larish text-lg">Quanto è importante lavorare con i giusti partner (imprese edili,
                            artigiani, aziende, fornitori...) per realizzare dei buoni progetti e come li scegli?</p>
                        <p>
                            {{ form.design3 }}
                        </p>

                        <h4 class="mb-5 mt-10">La sostenibilità</h4>
                        <p class="font-larish text-lg">La sostenibilità ambientale, sociale e di governance è un tema
                            sempre più rilevante per le persone e le comunità.
                            <br/> Questi principi come trovano riscontro nella vostra progettazione?</p>
                        <p>
                            {{ form.sustainability }}
                        </p>
                    </div>

                    <MButton class="mb-10" primary @click="goToStep(2)">
                        Modifica risposte
                    </MButton>

                    <div class="border border-primary p-3 mb-3">
                        <h4 class="mb-5">Tra progetti di posa e caratteristiche di prodotto</h4>
                        <p class="font-larish text-lg">Raccontateci i dettagli di un cantiere dove avete utilizzato i
                            prodotti per la posa Kerakoll.</p>
                        <p>
                            {{ form.product1 }}
                        </p>
                        <p class="font-larish text-lg">La posa nei cantieri è un momento determinante e da eseguire con
                            la massima cura, scegliendo i materiali adeguati per ogni supporto. Nei vostri progetti,
                            qual è
                            stato il valore aggiunto nell’utilizzare i prodotti Kerakoll? <br/> Quali prestazioni vi
                            hanno
                            garantito?</p>
                        <p>
                            {{ form.product2 }}
                        </p>

                        <h4 class="mb-5 mt-10">Altro</h4>
                        <p class="font-larish text-lg">Aggiungete qualsiasi altra informazione utile per valorizzare il
                            vostro progetto. (ad esempio premi, certificazioni, etc..)</p>
                        <p>
                            {{ form.other ? form.other : 'Nessuna informazione inserita' }}
                        </p>
                    </div>

                    <MButton class="mb-10" primary @click="goToStep(3)">
                        Modifica risposte
                    </MButton>

                    <div class="border border-primary p-3 mb-3">
                        <h4 class="mb-5">Immagini</h4>
                        <p class="font-larish text-lg">Ritratto</p>
                        <div class="flex flex-wrap gap-4 mb-5">
                            <img v-for="file in form.portrait" :key="file.id" :src="file.preview" class="image-preview"
                                 alt="portrait"/>
                        </div>
                        <p class="font-larish text-lg">Immagini di progetti finiti citati nelle risposte</p>
                        <div class="flex flex-wrap gap-4 mb-5">
                            <img v-for="file in form.projects" :key="file.id" :src="file.preview" class="image-preview"
                                 alt="projects"/>
                        </div>
                        <p class="font-larish text-lg">Immagini di “lavori in corso” dei progetti citati nelle
                            risposte</p>
                        <div class="flex flex-wrap gap-4 mb-5">
                            <img v-for="file in form.workInProgress" :key="file.id" :src="file.preview"
                                 class="image-preview" alt="workInProgress"/>
                        </div>
                        <p class="font-larish text-lg">Immagini di progetti di posa, anche non citati nelle risposte, in
                            cui sono stati utilizzati i prodotti del Sistema posa Kerakoll e in particolare i Gel-
                            Adesivi H40</p>
                        <div class="flex flex-wrap gap-4 mb-5">
                            <img v-for="file in form.poseProjects" :key="file.id" :src="file.preview"
                                 class="image-preview" alt="poseProjects"/>
                        </div>
                        <p class="font-larish text-lg">Documentazione video dei progetti citati, sia conclusi sia in
                            fase di realizzazione</p>
                        <div v-if="form.documents.length" class="flex flex-wrap gap-4 mb-5">
                            <video v-for="file in form.documents" :key="file.id" :src="file.preview"
                                   class="image-preview" alt="documents"/>
                        </div>
                        <div v-else>
                            Nessun file caricato
                        </div>
                    </div>

                    <MButton class="mb-10" primary @click="goToStep(4)">
                        Modifica risposte
                    </MButton>
                </div>
            </Transition>

            <MAlert class="mt-20" v-if="alert.data.isVisible" :type="alert.data.type">
                {{ alert.data.content }}
            </MAlert>

            <div v-if="active !== 5" class="mt-16">
                <p class="text-center mb-3">
                    Stato completamento intervista: {{ active }} di 5
                </p>
                <div class="progress">
                    <div class="progress__bar" role="progressbar" :style="{ width: `${(active) * 20}%` }"
                         aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div class="flex justify-center gap-4 mt-5">
                    <MButton v-if="active > 1" @click="active--" secondary>
                        Indietro
                    </MButton>
                    <MButton v-if="active < 5" @click="onNext" primary>
                        <div class="flex items-center gap-3">
                            Avanti
                            <ArrowRightIcon class="w-4 h-4"/>
                        </div>
                    </MButton>
                </div>
            </div>

            <MButton v-else type="submit" full-width primary :loading="isLoading">
                Invia
            </MButton>
        </form>

        <ClientOnly>
            <KModal closable lg :is-open="isModalOpen" @close="isModalOpen = false">
                <div>
                    <iframe src="/terms.pdf" frameborder="0" class="w-full h-[80vh]"/>
                </div>
            </KModal>
        </ClientOnly>

        <ClientOnly>
            <KModal lg :is-open="isModalSubmitOpen" @close="isModalSubmitOpen = false">
                <div>
                    <div class="py-10 text-center">
                        <h2 class="mb-5">
                            Grazie per aver partecipato
                        </h2>
                        <p class="mb-5">
                            Il form è stato inviato con successo
                        </p>
                        <p class="mb-5">
                            Scopri di più sul nostro progetto visitando la pagina <a href="https://products.kerakoll.com/it-IT/h40/h40-supports-my-ideas" target="_blank" class="text-primary underline">H40 Supports My Ideas</a>.
                        </p>
                        <MButton class="mx-auto" @click="isModalSubmitOpen = false" primary>
                            Chiudi
                        </MButton>
                    </div>
                </div>
            </KModal>
        </ClientOnly>

        <teleport to="body">
            <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center">
                <div class="relative z-10">
                    <div class="spinner">
                        <div class="cube1"></div>
                        <div class="cube2"></div>
                    </div>
                </div>
                <MOverlay white blur/>
            </div>
        </teleport>
    </div>
</template>