import type {Ref} from "vue";
import type {Form, FormFileTypes} from "assets/js/types";

// export const getMimeTypeFromBase64 = (base64: any)  => {
//     return base64.match(/^data:([A-Za-z-+\/]+);base64/)[1]
// }

export const getMimeTypeFromBase64 = (dataUriBase64: any)  => {
    // Split the data URI at the comma
    const parts = dataUriBase64.split(',');

    // Check if the first part starts with 'data:' to ensure it's a valid data URI
    if (parts.length < 2 || !parts[0].startsWith('data:')) {
        throw new Error('Invalid data URI');
    }

    // Extract the mimetype from the first part
    const mimeTypePart = parts[0];
    const mimeType = mimeTypePart.substring(mimeTypePart.indexOf(':') + 1, mimeTypePart.indexOf(';'));

    return mimeType;
}

// export const appendFilesAndInfo = (category: FormFileTypes, body: FormData, form: Ref<Form>)  => {
//     form.value[category].forEach((file) => {
//         if (file.raw) body.append(`${category}[]`, file.raw);
//     });
//     const info = form.value[category].map((file) => {
//         return {
//             name: file.name,
//             type: getMimeTypeFromBase64(file.preview)
//         };
//     });
//     body.append(`${category}Info`, JSON.stringify(info));
// }

export const appendFormValues = (fields: (keyof Form)[], body: FormData, form: Ref<Form>) => {
    fields.forEach(field => {
        if (form.value[field]) {
            body.append(field, form.value[field] as string);
        }
    });
}

export const fillForm = (form: Ref<Form>) => {
    if (form.value.name) return;
    form.value.name = 'John'
    form.value.surname = 'Doe'
    form.value.email = 'mu@mb.le'
    form.value.prefix = '+39'
    form.value.phone = '1234567890'
    form.value.studio = 'Studio'
    form.value.cap = '12345'
    form.value.qualification = 'Architetto'
    form.value.sector = 'Architettura'
    form.value.professionalPath = 'Professional path 1'
    form.value.design1 = 'Design 1'
    form.value.design2 = 'Design 2'
    form.value.design3 = 'Design 3'
    form.value.sustainability = 'Sustainability'
    form.value.product1 = 'Product 1'
    form.value.product2 = 'Product 2'
    form.value.other = 'Other'
    form.value.terms = true
    form.value.marketing = true
}

export const createFieldsFromBody = (body: any, exceptions: string[]) => {
    const fields: any = { fields: {} };

    // Iterate over each key in the body data
    for (const [key] of body) {
        // Skip the key if it's in the exceptions list
        if (exceptions.includes(key)) continue;
        fields.fields[key] = {
            'it': body.get(key) as string
        };
    }

    return fields;
}