<script setup lang="ts">
import { computed, onBeforeUnmount, onUpdated, ref, useSlots, watch } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import anime from 'animejs'
import { uuid } from '@mumble/helpers'
import useFocusTrap from '@mumble/components/utils/useFocusTrap'

interface Props {
    isOpen: boolean
    lg?: boolean
    sm?: boolean
    closable?: boolean
    class?: string
    blur?: boolean
    closeOnOverlayClick?: boolean
    teleport?: string
}

const props = withDefaults(defineProps<Props>(), {
    lg: false,
    sm: false,
    closable: false,
    blur: false,
    class: '',
    teleport: 'body',
    closeOnOverlayClick: true,
})

const emit = defineEmits<{
    (e: 'close'): void
}>()

const close = () => emit('close')

const slots = useSlots()

const hasHeader = computed(() => !!slots.header)
const hasFooter = computed(() => !!slots.footer)
const id = ref(uuid())

watch(
    () => props.isOpen,
    (value) => {
        const body = document.querySelector('body')!
        value ? body.classList.add('overflow-hidden') : body.classList.remove('overflow-hidden')
    },
    { immediate: true }
)

onBeforeUnmount(() => {
    document.querySelector('body')!.classList.remove('overflow-hidden')
})

const modal = ref()
const { trapRef } = useFocusTrap()

onUpdated(() => {
    const inputContainer = slots.default ? slots.default()[0]?.ctx?.refs.initial : null
    if (inputContainer) {
        inputContainer.querySelector('label').focus()
    } else {
        const parentSlots = slots.default && slots.default()[0]?.ctx?.slots.default ? slots.default()[0].ctx.slots.default() : undefined
        if (parentSlots && parentSlots.length) {
            const input = parentSlots.find((slot: any) => slot.props?.ref === 'initial')
            if (input && input.ctx.refs.initial) {
                const inputContainer = input.ctx.refs.initial.$el
                inputContainer.querySelector('label').focus()
            }
        }
    }
})

const onEnter = (el: HTMLElement, done: () => null) => {
    anime({
        targets: el.querySelector('.modal__overlay'),
        easing: 'easeInOutSine',
        duration: 200,
        opacity: [0, props.blur ? '100%' : '50%'],
    })
    anime({
        targets: el.querySelector('.modal__element'),
        opacity: [0, 1],
        scale: ['80%', '100%'],
        duration: 700,
        complete: done,
    })
}

const onLeave = (el: HTMLElement, done: () => null) => {
    anime({
        targets: el.querySelector('.modal__overlay'),
        easing: 'easeInOutSine',
        duration: 200,
        opacity: ['50%', 0],
    })
    anime({
        targets: el.querySelector('.modal__element'),
        easing: 'easeOutSine',
        opacity: [1, 0],
        scale: ['100%', '80%'],
        duration: 100,
        complete: done,
    })
}

const classes = computed(() => [
    'modal',
    props.class,
    {
        'modal--lg': props.lg,
        'modal--sm': props.sm,
        'modal--blur': props.blur,
    },
])

const onClickOverlay = () => {
    if (props.closeOnOverlayClick) emit('close')
}
</script>

<template>
    <Teleport :to="teleport">
        <Transition :css="false" appear @enter="onEnter" @leave="onLeave">
            <div v-if="isOpen" ref="trapRef" :class="classes" @keydown.esc="close">
                <span class="modal__overlay" aria-hidden="true" @click="onClickOverlay" />
                <div class="modal__container" tabindex="-1">
                    <section
                        ref="modal"
                        class="modal__element"
                        role="dialog"
                        aria-modal="true"
                        :aria-labelledby="`modal-header-${id}`"
                        :aria-describedby="`modal-content-${id}`"
                    >
                        <p v-if="closable" class="opacity-0">{{ closable }}</p>
                        <button v-if="closable" aria-label="Close" class="modal__close" @click="close">
                            <XMarkIcon class="modal__close-icon" />
                        </button>
                        <header v-if="hasHeader" :id="`modal-header-${id}`" class="modal__header">
                            <slot name="header"></slot>
                        </header>
                        <div :id="`modal-content-${id}`" class="modal__content">
                            <slot></slot>
                        </div>
                        <footer v-if="hasFooter" class="modal__footer">
                            <slot name="footer"></slot>
                        </footer>
                    </section>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>
